export default {
  methods: {
    userHasAnswerPermission(request) {
      return this.hasApproveSepaUpdatePermission && !this.currentUserCreatedRequest(request);
    },

    currentUserCreatedRequest(request) {
      if (this.isSuperAdmin) {
        return false;
      }

      return request.creator.id === this.$auth.user().id;
    }
  }
};
