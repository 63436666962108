<template>
  <app-data-table
    :headers="filterItemsWithFulfilledCondition(tableHeaders)"
    :items="formattedTableData"
    :loading="loadingList"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <div :class="classesTitleLeft">
          <div class="d-flex align-baseline">
            {{ $tc('requests.requestsCounted', count) }}
            <slot name="csv-download"></slot>
          </div>
        </div>
      </div>
    </template>

    <template #item="{ item }">
      <tr :class="{ 'v-data-table-row--error': item.userActive === false }">
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          {{ item.status | formatSepaRequestStatus }}
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          {{ $t('requests.sepaUpdate') }}
          <div class="subtitle-1 grey--text">
            {{ item.productTrans }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          <div class="semi-bold">{{ item.creditor.name }}</div>
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          {{ item.creditor.sapNumber || '-' }}
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          {{ item.sepaMandate.accountOwner }}
          <div>
            {{ item.sepaMandate.iban }}
          </div>
          {{ item.sepaMandate.start | formatDate }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.created | formatDate }}
        </app-table-data>
        <app-table-data :sorted="isSorted(6)" :header="tableHeaders[6]">
          {{ item.decided | formatDate }}
        </app-table-data>
        <td>
          <div v-if="currentUserCreatedRequest(item)" class="text--muted">
            {{ $t('requests.createdFromCurrentUser') }}
          </div>
          <app-btn
            v-else-if="item.isOpen"
            :disabled="!userHasAnswerPermission(item)"
            min-height="unset"
            class="py-1 px-2"
            outlined
            @click="setQueryParam(queryParam.ANSWER_REQUEST, item.id)"
          >
            {{ $t('edit') }}
          </app-btn>
          <span v-else>-</span>
        </td>
      </tr>
    </template>

    <template #no-data>
      <p>{{ $t('requests.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import formatProduct from '@/helper/filter/formatProduct';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';
import TableMixin from '@/mixins/TableMixin';
import RequestPermissionMixin from '@/modules/Admin/mixins/RequestPermissionMixin';

export default {
  name: 'RequestsTable',

  mixins: [TableMixin, RequestPermissionMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['requests', 'loadingList']),

    formattedTableData() {
      return JSON.parse(JSON.stringify(this.tableData)).map((entry) => ({
        ...entry,
        productTrans: formatProduct(entry.creditor.product)
      }));
    },

    tableHeaders() {
      return [
        { text: this.$t('status'), value: 'status' },
        {
          text: this.$t('type'),
          subText: this.$t('product'),
          alignLeft: true,
          value: '',
          sortable: false
        },
        {
          text: this.$t('creditors.creditorName'),
          value: 'creditorName',
          alignLeft: true
        },
        { text: this.$t('sapNumber'), value: 'sapNumber', alignLeft: true },
        {
          text: this.$t('requests.sepaData'),
          subText: `(${this.$t('iban')}, ${this.$t('accountOwner')}, ${this.$t('date')})`,
          value: 'iban',
          sortable: false,
          alignLeft: true
        },
        {
          text: this.$t('createdOn'),
          value: 'created'
        },
        {
          text: this.$t('requests.decidedOn'),
          value: 'decisionDate'
        },
        { text: this.$t('users.actions'), value: null, sortable: false }
      ];
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table.app-data-table tr td {
  vertical-align: middle;
}
</style>
