<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-if="details"
      v-model="open"
      :loading="loading"
      :disabled="!selectedAnswer || failed"
      primary-action-text="workQueue.submit"
      secondary-action-text="navigation.cancel"
      title="requests.sepaUpdate"
      @submit="submit"
    >
      <app-input-row dense label="creditor" :text-right="false">
        {{ details.creditor.name }}
      </app-input-row>

      <app-input-row dense label="requests.newBankDetails" :text-right="false">
        <app-labeled-data
          wrap
          no-gutters
          label="accountOwner"
          :value="details.sepaMandate.accountOwner"
        />
        <app-labeled-data wrap no-gutters label="iban" :value="details.sepaMandate.iban" />
        <app-labeled-data wrap no-gutters label="date">
          {{ details.sepaMandate.start | formatDate }}
        </app-labeled-data>
      </app-input-row>

      <div class="grey-background pa-4 mt-4">
        <div class="mb-4 semi-bold">{{ $t('requests.makeDecision') }}:</div>
        <app-radio-group
          v-model="selectedAnswer"
          name="requests.yourDecision"
          required
          :items="actionItems"
        />
      </div>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      :title="`requests.confirmedTitle${selectedAccept ? 'Accepted' : 'Declined'}`"
      :text="`requests.confirmedInfo${selectedAccept ? 'Accepted' : 'Declined'}`"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
const ACCEPT = 'accept';
const DECLINE = 'decline';

import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';

export default {
  name: 'AnswerSepaRequestDialog',

  mixins: [ShowDialogMixin],

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    selectedAnswer: ''
  }),

  watch: {
    details() {
      this.resetForm();
    }
  },

  computed: {
    selectedAccept() {
      return this.selectedAnswer === ACCEPT;
    },
    actionItems() {
      return [
        {
          label: this.$t('requests.acceptRequest'),
          value: ACCEPT
        },
        {
          label: this.$t('requests.declineRequest'),
          value: DECLINE
        }
      ];
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['acceptSepaRequest', 'declineSepaRequest']),

    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await (this.selectedAccept
        ? this.acceptSepaRequest(this.details.id)
        : this.declineSepaRequest(this.details.id));

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    },

    resetForm() {
      this.selectedAnswer = '';
      this.$refs.form?.reset();
    }
  }
};
</script>

<style scoped>
>>> .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0rem;
  flex: 0 0 50%;
}
</style>
