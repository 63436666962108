<template>
  <app-select
    filter
    :items="states"
    :label="$t('status')"
    label-icon="icon-gauge"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { REQUEST_STATES } from '@/modules/Admin/statics/requestStatus';
import formatSepaRequestStatus from '@/helper/filter/formatSepaRequestStatus';

export default {
  name: 'StatusSelect',

  computed: {
    states() {
      return REQUEST_STATES.map((status) => ({
        label: formatSepaRequestStatus(status),
        value: status
      }));
    }
  }
};
</script>
