<template>
  <v-row dense>
    <v-col cols="4" md="3" lg="2">
      <status-select v-model="statusSelection" />
    </v-col>

    <v-col cols="12" class="pt-3 pb-4">
      <active-filter-chip
        v-for="(selection, index) in statusSelection"
        :key="selection"
        v-model="statusSelection"
        :index="index"
        icon="icon-gauge"
      >
        {{ selection | formatSepaRequestStatus }}
      </active-filter-chip>

      <app-text-btn
        v-if="hasActiveFilters"
        color="background-text"
        class="caption"
        title="resetFilter"
        @click="resetAllFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import StatusSelect from '@/modules/Admin/components/Requests/StatusSelect';

export default {
  name: 'RequestsFilter',

  mixins: [ListFilterMixin],

  components: {
    ActiveFilterChip,
    StatusSelect
  },

  computed: {
    statusSelection: {
      get() {
        return this.getFilterQueryParam('status');
      },
      set(value) {
        this.setFilterQueryParam('status', value);
      }
    },
    hasActiveFilters() {
      return [].concat(this.statusSelection).length > 0;
    }
  }
};
</script>
